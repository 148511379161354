.entry-content{
    width: 100%;
    padding-top: var(--teft-theme-gap);
}

.entry-content > div[data-portal-region="main"] > *{
    width: 100%;
    padding-inline: 3rem;
    box-sizing: border-box;

    @media ( max-width: 583px ) {
        width: 100%;
        padding-inline: 1rem;
    }
    @media ( min-width: 584px ) and ( max-width: 767px ) {
        width: 100%;
        padding-inline: 1.5rem;
    }

    .col-lg-12 {
        padding-inline: 0 !important;
    }
}

.scroll-top {
	display: none;
    position: fixed;
    bottom: 20px;
    right: 30px;
    z-index: 99;
    border: none;
    outline: none;
    background-color: #000000ba;
    color: #fff;
    cursor: pointer;
    padding: 15px;
    border-radius: 10px;
    font-size: 18px;
}

.scroll-top:hover {
	background-color: #555; /* Add a dark-grey background on hover */
}

.u-align-left{text-align: left;}
.u-align-center{text-align: center;}
.u-align-right{text-align: right;}

.breadcrumbs{
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 40px;

    padding-inline: 3rem;

    @media ( max-width: 583px ) {
        padding-inline: 1rem;
    }
    @media ( max-width: 767px ) {
        padding-inline: 1.5rem;
    }

    .col-lg-12 {
        padding: 0 !important;
    }

    a{
    text-decoration: none;
    font-size: var(--teft-typography-m);
    color: #000000;
    font-weight: 400;
    }

    .breadcrumb-separator{
        padding: 0 10px;
    }
}

.entry-title{
    color: var(--color-orange);
    text-align: center;
    font-size: var(--teft-typography-xxxl);
    font-weight: var(--avn-weight-bold);
    line-height: 1.4em;
    margin-block: 29.48px;

    @media ( min-width: 1025px ) {
        margin-block: 36.85px;
    }
}

.htmlArea{
    figure{
        margin-bottom: 15px;
    }

    .editor-align-right{
        margin-left: 30px;
    }

    a{
        color: var(--color-turquoise-dark);
    }

    ul,ol{
        margin: 0px 0 40px 0;
        list-style: initial;
        list-style-position: outside;
        padding-left: 0;
        li{
            margin-bottom: 6px;
            margin-left: 2.5em;
        }
        a{
            font-style: initial;
        }
    }

    h1,h2,h4,h5,h6{
        margin-top: 25px;
        &+p{
            margin-top: -12px;
        }
    }
    h3{
        margin-top: 25px;
        &+p{
            margin-top: -7px;
        }
    }

    h1{
        font-weight: var(--avn-weight-bold);
        font-size: 55px;
        line-height: 1.2;
        font-weight: 700;
        @media ( max-width: 1025px ) {
            font-size: 44px;
        }
    }
    h2{
        font-weight: var(--avn-weight-regular);
        font-size: 40px;
        line-height: 1.2;
        font-weight: 400;

        @media ( max-width: 1025px ) {
            font-size: 32px;
        }
    }
    h3{
        font-size: var(--teft-typography-xl);
        font-weight: var(--avn-weight-regular);
        @media ( max-width: 1025px ) {

        }
    }
    h4{
        font-size: 24px;
        line-height: 32px;
        font-weight: var(--avn-weight-regular);
        strong{
            font-weight: var(--avn-weight-bold);
            margin-bottom: 25px;
            display: inline-block;
        }
        @media ( max-width: 1025px ) {

        }
    }
    h5{
        font-size: var(--teft-typography-m);
        font-weight: var(--avn-weight-bold);
        @media ( max-width: 1025px ) {

        }
    }
    h6{
        font-size: 0.67em;
        font-weight: var(--teft-typography-heading-font-weight);
        @media ( max-width: 1025px ) {

        }
    }
    p{
        font-size: 20px;
        line-height: 1.4;
        margin-bottom: 40px;
        @media ( max-width: 1025px ) {
            font-size: 16px;
            line-height: 1.8;
        }
        &:last-child{
            margin-bottom: 0;
        }
        &+h2{
            margin-top: -15px;
        }
        &+h3{
            margin-top: -10px;
        }
    }
    .link-box {
        margin-top: 50px;
        @media ( max-width: 1025px ) {
            margin-top: 40px;
        }
    }

}

.knapp-button {
    border: 2px solid;
    text-decoration: none;
    padding: 0.5em 1.5em;
    display: inline-block;
    margin: 10px 0;
    min-width: 180px;
    text-align: center;
    font-size: 18px;
    border-radius: 2px;
}

.knapp-button:hover {
    opacity: .85;
}

.knapp-small-button {
    background: #32373c;
    border-radius: 2em;
    color: #fff !important;
    font-size: 13px;
    padding: 0.5em 1em;
    text-decoration: none;
}

.knapp-small-button:hover {
    opacity: .85;
}


// TODO: Temp. fix. These classes are added here as "gulp css" does not produce the same styles.min.css as in production
@media (min-width: 1100px) {
    .container,
    .container-xs,
    .container-sm,
    .container-md,
    .container-lg,
    .container-xl {
        max-width: 1100px;
    }
}

.justify-content-center {
    justify-content: center !important;
}
